<template>
  <v-dialog v-model="dialog" width="900">
    <v-card>
      <v-card-title v-if="selectedForm">Tarkista luottotiedot</v-card-title>

      <v-card-text v-if="selectedFormSubmission">
        Täytetty: {{ formatDate(selectedFormSubmission.item.createdAt) }}</v-card-text
      >

      <v-card-text>
        <div class="submissions" v-for="(key, i) in formFields" :key="'a' + i">
          <div class="headline-wrapper">
            <strong>{{ key }}:</strong>
          </div>
          <div class="text-wrapper">
            {{ formatField(selectedFormSubmission.item.fields[key], key) }}
          </div>
          <div class="text-wrapper">
            <v-select
              v-model="selectedFields[key]"
              :items="tenantFields.map((item) => ({ ...item, disabled: disableItems(item) }))"
              label="Valitse vastaava kenttä"
              item-text="text"
              item-value="value"
              clearable
              dense
            ></v-select>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="checkCreditReport">Tarkista luottotiedot</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tenantFields: [
        { text: "Hetu", value: "social" },
        { text: "Y-tunnus", value: "businessId" },
      ],
      selectedFields: {},
    };
  },

  computed: {
    ...mapState("formSubmission", ["selectedFormSubmission"]),
    ...mapState("form", ["selectedForm"]),

    formFields() {
      if (this.selectedFormSubmission) {
        return Object.keys(this.$store.state.formSubmission.selectedFormSubmission.item.fields);
      }
      return [];
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    formatField(value, key) {
      if (this.selectedForm) {
        const meta = this.selectedForm.oldMetadata;
        if (key in meta) {
          if (meta[key].type == "boolean") {
            return this.formatBoolean(value);
          }
          if (meta[key].type == "date") {
            return this.formatDate(value);
          }
          return value;
        } else {
          return value;
        }
      }
    },

    checkCreditReport() {
      const formFields = this.$store.state.formSubmission.selectedFormSubmission.item.fields;
      const query = {};
      let queryOk = false;

      for (const [key, value] of Object.entries(this.selectedFields)) {
        if (value) {
          if (value == "businessId") {
            query[value] = formFields[key];
            query["isCompany"] = true;
            queryOk = true;
          } else if (value == "social") {
            query[value] = formFields[key];
            query["isCompany"] = false;
            queryOk = true;
          }
        }
      }

      if (!queryOk) {
        return this.showPopup(
          "Valitse henkilötunnusta tai y-tunnusta vastaava kenttä ensin",
          "error"
        );
      }

      this.$router.push(
        `/credit-reports?state=vuokranet&isCompany=${query.isCompany}&govid=${
          query.isCompany ? query.businessId : query.social
        }`
      );
    },

    disableItems(item) {
      if (item.value == "businessId" && Object.values(this.selectedFields).includes("social")) {
        return true;
      }
      if (item.value == "social" && Object.values(this.selectedFields).includes("businessId")) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.submissions {
  display: flex;
  flex-wrap: wrap;
}

.headline-wrapper {
  width: 200px;
  flex-shrink: 0;
  padding-right: 10px;
}

.text-wrapper {
  flex: 1;
}

@media (max-width: 550px) {
  .submissions {
    flex-direction: column;
    align-items: flex-start;
  }

  .headline-wrapper {
    width: 100%;
  }
}
</style>
