<template>
  <v-dialog v-model="dialog" width="900">
    <v-card>
      <v-card-title v-if="selectedForm">Lisää vuokralaiseksi</v-card-title>

      <v-card-text v-if="selectedFormSubmission">
        Täytetty: {{ formatDate(selectedFormSubmission.item.createdAt) }}</v-card-text
      >

      <v-card-text>
        <div class="submissions" v-for="(key, i) in formFields" :key="'a' + i">
          <div class="headline-wrapper">
            <strong>{{ key }}:</strong>
          </div>
          <div class="text-wrapper">
            {{ formatField(selectedFormSubmission.item.fields[key], key) }}
          </div>
          <div class="text-wrapper">
            <v-select
              v-model="selectedFields[key]"
              :items="tenantFields.map((item) => ({ ...item, disabled: disableItems(item) }))"
              label="Valitse vastaava kenttä"
              item-text="text"
              item-value="value"
              clearable
              dense
            ></v-select>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="createNewTenant">Luo vuokralainen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "@/mixins/mixins";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      tenantFields: [
        { text: "Nimi", value: "name" },
        { text: "Hetu", value: "social" },
        { text: "Y-tunnus", value: "businessId" },
        { text: "Suomalainen henkilötunnus", value: "finnishCitizen" },
        { text: "Email", value: "email" },
        { text: "Puhelin", value: "phone" },
        { text: "Osoite", value: "address" },
        { text: "Postinumero", value: "zipCode" },
        { text: "Kaupunki", value: "city" },
      ],
      selectedFields: {},
    };
  },

  computed: {
    ...mapState("formSubmission", ["selectedFormSubmission"]),
    ...mapState("form", ["selectedForm"]),

    formFields() {
      if (this.selectedFormSubmission) {
        return Object.keys(this.$store.state.formSubmission.selectedFormSubmission.item.fields);
      }
      return [];
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        if (!value) this.selectedFields = {};
      },
    },
  },

  methods: {
    ...mapActions("tenant", ["createTenant"]),

    async createNewTenant() {
      const tenant = {};
      const formFields = this.$store.state.formSubmission.selectedFormSubmission.item.fields;

      if (this.validateFields()) {
        for (const [key, value] of Object.entries(this.selectedFields)) {
          if (value) {
            if (value == "businessId") {
              tenant[value] = formFields[key];
              tenant["isCompany"] = true;
            } else if (value == "social") {
              tenant[value] = formFields[key];
              tenant["isCompany"] = false;
            } else {
              tenant[value] = formFields[key];
            }
          }
        }

        try {
          // Create tenant
          await this.createTenant(tenant);
          this.dialog = false;
          this.showPopup("Uusi vuokralainen luotu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    validateFields() {
      let fieldsOk = true;
      let containsMandatoryFields = false;

      for (const [key, value] of Object.entries(this.selectedFields)) {
        if (value == "name") containsMandatoryFields = true;

        if (value !== null) {
          const expectedFieldType = this.selectedForm.oldMetadata[key].fieldType;
          const expectedType = this.selectedForm.oldMetadata[key].type;

          if (value == "email") {
            if (expectedFieldType != "email") fieldsOk = false;
          } else if (value == "finnishCitizen") {
            if (expectedType != "boolean") fieldsOk = false;
          } else {
            if (expectedFieldType != "text") fieldsOk = false;
          }
        }
      }

      if (!containsMandatoryFields) {
        this.showPopup("Nimi on valittava.", "error");
        return false;
      }

      if (!fieldsOk) {
        this.showPopup(
          "Valitut kentät eivät vastaa oikeaa tietotyyppiä. Tarkista kenttien valinnat.",
          "error"
        );
        return false;
      }

      return true;
    },

    formatField(value, key) {
      if (this.selectedForm) {
        const meta = this.selectedForm.oldMetadata;
        if (key in meta) {
          if (meta[key].type == "boolean") {
            return this.formatBoolean(value);
          }
          if (meta[key].type == "date") {
            return this.formatDate(value);
          }
          return value;
        } else {
          return value;
        }
      }
    },

    disableItems(item) {
      if (item.value == "businessId" && Object.values(this.selectedFields).includes("social")) {
        return true;
      }
      if (item.value == "social" && Object.values(this.selectedFields).includes("businessId")) {
        return true;
      }

      const alreadySelected = Object.values(this.selectedFields).includes(item.value);

      if (alreadySelected) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.submissions {
  display: flex;
  flex-wrap: wrap;
}

.headline-wrapper {
  width: 200px;
  flex-shrink: 0;
  padding-right: 10px;
}

.text-wrapper {
  flex: 1;
}

@media (max-width: 550px) {
  .submissions {
    flex-direction: column;
    align-items: flex-start;
  }

  .headline-wrapper {
    width: 100%;
  }
}
</style>
