<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card>
      <v-card-title v-if="selectedForm">Lomake: {{ selectedForm.name }}</v-card-title>

      <v-card-text v-if="selectedFormSubmission">
        Täytetty: {{ formatDate(selectedFormSubmission.item.createdAt) }}</v-card-text
      >

      <v-card-text>
        <div
          :class="['submissions', { active: selectedField === key }]"
          v-for="(value, key, i) in fields"
          :key="'a' + i"
        >
          <div class="headline-wrapper" @click="toggleAndSaveFieldComment(key)">
            <strong>{{ key }}:</strong>
          </div>

          <div class="text-wrapper" @click="toggleAndSaveFieldComment(key)">
            {{ value }}
          </div>

          <div v-if="comments[key]" class="comment-wrapper" @click="toggleAndSaveFieldComment(key)">
            Kommentti: {{ comments[key] }}
          </div>

          <div v-if="selectedField === key" class="comment-field-wrapper mt-1">
            <v-text-field
              v-model.trim="comments[key]"
              :label="`${key} kommentti`"
              outlined
              dense
              @click.stop
              hide-details
            ></v-text-field>
            <v-btn small color="info" class="mt-1 mb-1" @click="toggleAndSaveFieldComment(key)"
              >Tallenna</v-btn
            >
          </div>
        </div>
      </v-card-text>

      <v-card-text v-if="Object.keys(oldFields).length > 0">
        <h4 class="mb-1 warning--text">Nykyiseltä lomakkeelta poistetut kentät</h4>

        <div
          v-for="(value, key, i) in oldFields"
          :key="'b' + i"
          :class="['submissions', { active: selectedField === key }]"
        >
          <div class="headline-wrapper" @click="toggleAndSaveFieldComment(key)">
            <strong>{{ key }}:</strong>
          </div>

          <div class="text-wrapper" @click="toggleAndSaveFieldComment(key)">
            {{ value }}
          </div>

          <div v-if="comments[key]" class="comment-wrapper" @click="toggleAndSaveFieldComment(key)">
            Kommentti: {{ comments[key] }}
          </div>

          <div v-if="selectedField === key" class="comment-field-wrapper mt-1">
            <v-text-field
              v-model="comments[key]"
              :label="`${key} kommentti`"
              outlined
              dense
              @click.stop
              hide-details
            ></v-text-field>
            <v-btn small color="info" class="mt-1 mb-1" @click="toggleAndSaveFieldComment(key)"
              >Tallenna</v-btn
            >
          </div>
        </div>
      </v-card-text>

      <v-card-text v-if="images.length > 0">
        <h4>Kuvat</h4>
        <form-submission-images></form-submission-images>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "@/mixins/mixins";
import FormSubmissionImages from "./FormSubmissionImages.vue";
import _ from "lodash";
export default {
  mixins: [mixins],

  components: {
    FormSubmissionImages,
  },

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      comments: {},
      selectedField: null,
      originalComment: "",
    };
  },

  computed: {
    ...mapState("formSubmission", ["selectedFormSubmission", "images"]),
    ...mapState("form", ["selectedForm"]),

    submissionFields() {
      if (this.selectedFormSubmission) {
        return this.selectedFormSubmission.item.fields;
      }
      return null;
    },

    rows() {
      if (this.selectedForm) {
        return this.selectedForm.rows;
      } else {
        return [];
      }
    },

    fields() {
      const fields = {};

      if (this.rows.length > 0 && this.submissionFields) {
        this.rows.forEach((row) => {
          row.cols.forEach((col) => {
            if (col.label && col.label in this.submissionFields) {
              const value = this.formatField(this.submissionFields[col.label], col.label);
              fields[col.label] = value;
            }
          });
        });
        return fields;
      } else {
        return {};
      }
    },
    // These fields type have changed and they are not present anymore in rows or metadata, they are found in oldMetadata
    oldFields() {
      const oldFields = {};

      if (this.submissionFields) {
        for (const key in this.submissionFields) {
          if (!(key in this.fields)) {
            const value = this.formatField(this.submissionFields[key], key);
            oldFields[key] = value;
          }
        }

        return oldFields;
      } else {
        return {};
      }
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    selectedFormSubmission(val) {
      this.resetFields();
      this.getFieldComments(_.cloneDeep(val));
    },
  },

  methods: {
    ...mapActions("formSubmission", ["saveSubmissionFieldComment"]),

    resetFields() {
      this.comments = {};
      this.selectedField = null;
      this.originalComment = "";
    },

    getFieldComments(submission) {
      this.comments = submission.item?.fieldComments || {};
    },

    formatField(value, key) {
      if (this.selectedForm) {
        const meta = this.selectedForm.oldMetadata;
        if (key in meta) {
          if (meta[key].type == "boolean") {
            return this.formatBoolean(value);
          }
          if (meta[key].type == "date") {
            return this.formatDate(value);
          }
          return value;
        } else {
          return value;
        }
      }
    },

    async toggleAndSaveFieldComment(key) {
      if (this.selectedField && this.selectedField !== key) {
        this.showPopup("Tallenna aiemman rivin kommentti ennen kuin avaat uuden", "error");
        return;
      }

      const currentComment = this.comments[key] || "";

      if (this.selectedField === key) {
        if (currentComment !== this.originalComment) {
          try {
            await this.saveSubmissionFieldComment({
              formSubmissionId: this.selectedFormSubmission._id,
              key,
              value: this.comments[key],
            });
            this.showPopup("Kommentti tallennettu", "success");
          } catch (err) {
            this.showPopup(err, "error");
          }
        }
        this.selectedField = null;
        this.originalComment = "";
      } else {
        this.originalComment = this.comments[key] || "";
        this.selectedField = key;
      }
    },
  },
};
</script>

<style scoped>
.submissions {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.headline-wrapper {
  width: 250px;
  flex-shrink: 0;
  padding-right: 20px;
}

.text-wrapper {
  flex: 1;
  overflow-wrap: break-word;
}

.comment-wrapper {
  flex: 1;
  padding-left: 20px;
  font-style: italic;
}

.submissions:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.submissions.active:hover {
  background-color: transparent;
}

.comment-field-wrapper {
  width: 100%;
}

@media (max-width: 550px) {
  .submissions {
    flex-direction: column;
    align-items: flex-start;
  }

  .headline-wrapper,
  .text-wrapper,
  .comment-wrapper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .comment-wrapper {
    margin-top: 3px;
  }
}
</style>
