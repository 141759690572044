<template>
  <v-dialog v-model="dialog" fullscreen persistent>
    <v-card>
      <v-container fluid>
        <v-toolbar flat height="40px">
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>

      <v-carousel
        v-model="imageIndex"
        height="100%"
        hide-delimiters
        show-arrows-on-hover
        :show-arrows="images.length > 1"
        @change="updateImageIndex"
      >
        <v-carousel-item v-for="(img, i) in images" :key="i">
          <h3 v-if="img.fieldLabel" class="text-center mb-1">{{ img.fieldLabel }}</h3>
          <v-img
            max-height="94vh"
            :src="imageUrls[imageIndex]"
            contain
            @error="reloadImage(img, i)"
          ></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";

export default {
  mixins: [mixins],

  props: {
    imageType: { type: String, default: "" },
    value: { type: Boolean, default: false },
    images: {
      type: Array,
      default: () => {
        return [];
      },
    },
    imageUrls: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      imageIndex: 0,
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    updateImageIndex(index) {
      this.imageIndex = index;
    },

    reloadImage(img, index) {
      setTimeout(() => {
        const imageUrl = this.getImageUrl(img);
        this.$set(this.imageUrls, index, imageUrl);
      }, 2000);
    },

    getImageUrl(img) {
      if (this.imageType === "formImage") {
        return this.getFormImageCloudFrontUrl(img);
      } else if (this.imageType === "image") {
        return this.getImageCloudFrontUrl(img);
      } else {
        return "";
      }
    },
  },
};
</script>
<style></style>
