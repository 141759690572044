<template>
  <div>
    <v-row v-if="!imageDialog" dense class="mt-1">
      <v-col
        style="
          padding: 0px;
          max-width: 120px;
          min-height: 120px;
          margin-left: 7px;
          margin-bottom: 20px;
        "
        v-for="(image, index) in images"
        :key="index"
      >
        <div
          style="
            padding: 0px;
            max-width: 120px;
            height: 120px;
            position: relative;
            border: 1px solid #ccc;
            box-sizing: border-box;
          "
        >
          <v-img
            :src="imageUrls[index]"
            @error="reloadImage(image, index)"
            max-height="120"
            style="width: 100%; height: 100%"
            contain
          ></v-img>

          <v-btn icon @click="viewImage(index)" small style="position: absolute; top: 0; left: 0">
            <v-icon size="15">mdi-arrow-expand-all</v-icon>
          </v-btn>
          <!-- <v-btn
            icon
            @click="deleteImage(image)"
            small
            style="position: absolute; bottom: 0; right: 0"
          >
            <v-icon size="15">mdi-delete</v-icon>
          </v-btn> -->
        </div>
        <label style="font-size: 11px; line-height: 0.5rem !important; letter-spacing: 0em">{{
          image.fieldLabel
        }}</label>
      </v-col>
    </v-row>

    <!-- Carousel -->
    <view-image-dialog
      v-model="imageDialog"
      :images="images"
      :imageUrls="imageUrls"
      imageType="formImage"
      ref="imageDialog"
    ></view-image-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";
import ViewImageDialog from "@/components/Image/ViewImageDialog";

export default {
  mixins: [mixins],

  components: {
    ViewImageDialog,
  },

  data() {
    return {
      imageUrls: [],
      imageDialog: false,
      imageIndex: 0,
    };
  },

  mounted() {
    this.updateImageUrls();
  },

  computed: {
    ...mapState("formSubmission", ["selectedFormSubmission", "images"]),
  },

  watch: {
    images: {
      immidiate: true,
      handler: "updateImageUrls",
    },
  },

  methods: {
    viewImage(index) {
      this.$refs.imageDialog.updateImageIndex(index);
      this.imageDialog = true;
    },

    updateImageUrls() {
      this.imageUrls = this.images.map((img) => this.getFormImageCloudFrontUrl(img));
    },

    reloadImage(img, index) {
      setTimeout(() => {
        const imageUrl = this.getFormImageCloudFrontUrl(img);
        this.$set(this.imageUrls, index, imageUrl);
      }, 2000);
    },
  },
};
</script>
<style></style>
